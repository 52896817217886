import { useEffect, useRef, useState } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { navListRootIndices } from '../../../../platform/client/navigation'
import getAsset from '../../../../platform/getAsset'
import Icon from './Icon'
import Purchase from './Purchase'
import TextInput from './TextInput'
import FileInput from './FileInput'
import Tile from './Tile'
import { debounce } from '@orangelv/utils'

import './ScrollMenu.css'

type OptionCateogry = {
  id: string
  name: string
  order: number
}

type Option = {
  id: string | null
  name: string
  description?: string
  category?: OptionCateogry
  props?: { tileImageSrc?: string; hex?: string }
}

type SelectInputProps = {
  onChange: (id?: string | null) => void
  options: Option[]
  tileImageTemplate: (option: Option) => string
  tileType: string
  value: string
  visibleOptions?: Option[]
  isRequired: boolean
  object: Option
}

type ScrollPosition = {
  isTop: boolean
  isBottom: boolean
  scrollTop: number
}

function SelectInput({
  onChange,
  options,
  tileImageTemplate,
  tileType,
  value,
  visibleOptions,
  isRequired,
  object: selectedOption,
}: SelectInputProps) {
  const [displayOption, setDisplayOption] = useState(selectedOption)

  let opts = visibleOptions ?? options
  let groupedOptions: Option[][] = []
  const hasCategories = opts.some(({ category }) => category !== undefined)

  if (hasCategories) {
    const optionsByCategory: Record<string, Option[]> = {}

    for (const item of opts) {
      if (item.category) {
        const category = item.category.id
        if (!optionsByCategory[category]) {
          optionsByCategory[category] = []
        }
        optionsByCategory[category].push(item)
      }
    }

    groupedOptions = Object.values(optionsByCategory).sort((a, b) => {
      const orderA = a[0]?.category?.order ?? Infinity
      const orderB = b[0]?.category?.order ?? Infinity

      return orderA - orderB
    })
  }

  if (!isRequired) {
    const noneOption: Option = { id: null, name: 'None' }
    if (hasCategories && groupedOptions.length > 0) {
      groupedOptions[0] = [noneOption, ...groupedOptions[0]]
    } else {
      opts = [noneOption, ...opts]
    }
  }

  const hasText = tileType.endsWith('WithText')
  const hasImageTemplate = !!tileImageTemplate

  const renderTile = (option) => {
    let imageSrc
    if (option.id != null) {
      if (option.props?.tileImageSrc) {
        imageSrc = getAsset(option.props.tileImageSrc)
      } else if (hasImageTemplate) {
        imageSrc = getAsset(tileImageTemplate(option))
      }
    }
    const dashed = option.id == null || option.id === 'no'

    return (
      <Tile
        background={dashed ? 'transparent' : 'midGray'}
        customColor={!imageSrc && option.props?.hex}
        dashed={dashed}
        imageSrc={imageSrc}
        isScrollMenu
        isSelected={option.id === value}
        isSmall={false}
        key={option.id ?? 'none'}
        onClick={() => onChange(option.id)}
        onMouseOut={() => setDisplayOption(selectedOption)}
        onMouseOver={() => setDisplayOption(option)}
        text={hasText && option.name}
        tileType={tileType}
      />
    )
  }

  return (
    <>
      <div className="select-input">
        {hasCategories ?
          groupedOptions.map((group) => {
            const category = group.find((item) => !!item.category)?.category
            if (!category) return null
            return (
              <div key={category.id} className="category-container">
                <div className="category-title">{category.name}</div>
                <div className="category-items">{group.map(renderTile)}</div>
              </div>
            )
          })
        : opts.map(renderTile)}
      </div>
      <div className="sub-title">
        <div className="name">{displayOption?.name ?? 'None'}</div>
        <div className="description">
          {displayOption?.description ? displayOption.description : ''}
        </div>
      </div>
    </>
  )
}

function ScrollMenuSection({
  name,
  effectiveId,
  node,
  onScrollChange,
  ...props
}) {
  const { ref } = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: '-30% 0px -40%',
    onChange: onScrollChange,
  })
  let input
  const mergedProps = { ...node, ...props }
  if (node.nodeKind === 'SelectNode') {
    input = <SelectInput {...(mergedProps as SelectInputProps)} />
  } else if (node.nodeKind === 'TextNode') {
    input = <TextInput {...mergedProps} />
  } else if (node.nodeKind === 'FileUploadNode') {
    input = <FileInput {...props} change={props.onChange} />
  } else {
    throw Error(`Unknown nodeKind: ${node.nodeKind}`)
  }

  return (
    <div ref={ref} className="scroll-menu-section">
      <span className="scrollAnchor" id={effectiveId} />
      <h2>{name}</h2>
      {input}
    </div>
  )
}

function getNavSections(navList) {
  return (
    navListRootIndices
      // First category is the "glove finder", we don't include it in this menu
      .slice(1)
      .map((i) => navList[i])
      .flatMap((category) =>
        category.childIndices
          .map((j) => navList[j])
          .filter((option) => option.isAvailable)
          .flatMap((option) => {
            if (option.childIndices.length === 0) {
              if (!option.node) {
                throw Error(`No node found: ${option.effectiveId}`)
              }
              return option
            }
            return option.childIndices
              .map((k) => navList[k])
              .filter((subOption) => subOption.isAvailable)
              .map((subOption) => {
                if (!subOption.node) {
                  throw Error(`No node found: ${subOption.effectiveId}`)
                }
                return {
                  ...subOption,
                  name: `${option.name} | ${subOption.name}`,
                }
              })
          }),
      )
  )
}

export default function ScrollMenu({
  navList,
  onCart,
  onChange,
  onSummary,
  openMenu,
  product,
}) {
  const debouncedOpenMenu = useRef<(id: string) => void>(
    debounce(openMenu, 100),
  )
  const sections = getNavSections(navList)
  const initialScrollMiddle = sections.map(() => false)
  initialScrollMiddle[0] = true
  const [scrollMiddle, setScrollMiddle] = useState(initialScrollMiddle)
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    isTop: true,
    isBottom: false,
    scrollTop: 0,
  })
  const previousScrollPositionRef = useRef(scrollPosition)
  const scrollMenuRef = useRef<HTMLDivElement | null>(null)
  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target
    const isBottom = scrollHeight - scrollTop === clientHeight
    const isTop = scrollTop === 0
    setScrollPosition({ isTop, isBottom, scrollTop })
  }

  useEffect(() => {
    if (
      scrollPosition.scrollTop !== previousScrollPositionRef.current.scrollTop
    ) {
      const id = sections?.[scrollMiddle.indexOf(true)]?.effectiveId
      if (id) {
        debouncedOpenMenu.current(id)
      }
      previousScrollPositionRef.current = scrollPosition
    }
  }, [sections, scrollMiddle, scrollPosition])
  return (
    <>
      <div ref={scrollMenuRef} className="scroll-menu" onScroll={handleScroll}>
        {!scrollPosition.isTop && (
          <div
            className="arrow arrow-up"
            onClick={() => {
              scrollMenuRef.current?.scrollBy({
                top: -window.innerHeight,
              })
            }}
          >
            <div className="arrow-icon">
              <Icon name="arrow-up" inverted />
            </div>
          </div>
        )}
        <div className="scroll-menu-sections">
          {sections.map((section, i) => (
            <ScrollMenuSection
              {...section}
              onScrollChange={(isIntersecting) =>
                setScrollMiddle((m) => {
                  const middle = m.slice()
                  middle[i] = isIntersecting
                  return middle
                })
              }
              key={section.effectiveId}
              onChange={(value) => {
                onChange(section.node.keyPath, value)
                openMenu(section.effectiveId)
              }}
            />
          ))}
          <div className="purchase-container">
            <Purchase
              isActive
              product={product}
              onCart={onCart}
              onSummary={onSummary}
            />
          </div>
          <div className="spacer" />
        </div>
        {!scrollPosition.isBottom && (
          <div
            className="arrow arrow-down"
            onClick={() => {
              scrollMenuRef.current?.scrollBy({
                top: window.innerHeight,
              })
            }}
          >
            <div className="arrow-icon">
              <Icon name="arrow-down" inverted />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
